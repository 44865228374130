export default {

    /*Mensajes*/ 
	"success_title_add_item_course"     : "Se ha agregado el producto",
	"success_subtitle_add_item_course"  : "a tu cotizacion",
	"success_title_delete_item_course"  : "Se ha quitado el producto",
	"success_title_success_checkout"    : "Se ha ingresado el pedido con éxito", 
	"success_subtitle_success_checkout" : "En breve nos pondremos en contacto contigo",
	"success_title_success_contact"     : "Hemos recibido tu mensaje", 
	"success_subtitle_success_contact"  : "En breve nos pondremos en contacto contigo",

    /*Validaciones*/
	"valid_required"  : "Es necesario llenar éste dato",
	"valid_email"     : "Correo no válido",
	"valid_telephone" : "Telefóno no válido",

};
