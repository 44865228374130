/*v.1.2*/
//import axios from "axios";

const _axios = axios.create({
    baseURL         : process.env.VUE_APP_API_URL_BASE,
    withCredentials : false,
    crossdomain     : true,
    headers         : {
        Accept         : "application/json",
        "Content-Type" : "application/json"
    }
});

class api_client_class {

    get(url, _params) {
      
        return new Promise((resolve,reject) => {
            _axios
                .get(
                    url,
                    this._getConfig(_params))
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    post(url, _data, _params) {

        let config = this._getConfig(_params);
        let data   = null;

        if(_data){
            if(_data instanceof FormData){

                config["headers"] = {
                    'Content-Type' : `multipart/form-data; boundary=${_data._boundary}` };
            } 

            data = _data;
        }

        return new Promise((resolve,reject) => {
            _axios
                .post(
                    url,
                    data,
                    this._getConfig(_params))
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    delete(url, _params) {

        return new Promise((resolve,reject) => {
            _axios
                .delete(
                    url,
                    this._getConfig(_params))
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    _getConfig(_params){

        if(!_params) _params = {};

        let config = {
            params  : _params,
        };
        
        return config;
    }

}

const api_client = new api_client_class();
export default api_client;
