import { formatCurrency } from "./helpers/format.hlp.js";
import { addBase
        ,appendString }   from './helpers/filters.hlp.js';

import { getDetallesCarrito,
         updateCantidadCarrito,
         removeItemCarrito }    from "./services/CarritoService.js";
import txt                      from './locales/es.js';

Vue.use(VueLoading);
Vue.component('Loading', VueLoading);

var vueForm = new Vue({

    el   : "#app-vue",
    data() {
		return {
            items     : [],
            total     : '',
            isLoading : false,
        }
	},
    filters : {
        addBase,
        appendString
    },
    watch : {
        items: {
            handler(val, oldVal){
                this.setTotal();
            },
            deep : true }
    },
    mounted : function() {

        this.getData();
    },
    methods : {
        getData : function(){
            
            this.isLoading = true;

            getDetallesCarrito()
                .then(response => {
                    console.log("response",response.data.datos);
                    this.items = response.data.datos;
                })
                .catch(error => {
                    console.error("error", error);
                })
                .finally( ()=>{
                    this.isLoading = false;
                });
        },
        quitaCarrito : function(index){

            let nombre = this.items[index].nombre;

            removeItemCarrito(this.items[index].id_variacion)
                .then(response => {

                    this.items.splice(index,1);
                    $(".cart-access").attr("data-notify",this.items.length);
                    Swal.fire(
                        txt.success_title_delete_item_course,
                        nombre,
                        'success');
                });
        },
        productUp : function(index){
            
            let cantidad = parseInt(this.items[index].cantidad,10) + 1;
            this.updateCantidad(cantidad, index);
        },
        productDown : function(index){
            if(this.items[index].cantidad > 1){

                let cantidad = parseInt(this.items[index].cantidad,10) - 1;
                this.updateCantidad(cantidad, index);
            }
        },
        updateCantidad : function(cantidad, index){

            updateCantidadCarrito(this.items[index].id_variacion, cantidad)
                .then(response => {
                    this.items[index].cantidad = cantidad;
                });
        },
        setTotal : function(){
            let total = 0;
            for( let _item of this.items )
                total += _item.cantidad * _item.precio_unitario;

            this.total = formatCurrency(total);
        },
        toCurrency : function(num){

            return num !== "" ? formatCurrency(num) : num;
        }
	},
});
