
export function addBase(value){
  if(value.substring(0,1) == "/" || value.substring(0,2) == "./"){
    
    // Le quitamos el punto del inicio
    if(value.substring(0,2) == "./"){
      value = value.substring(1)
    }

    return process.env.VUE_APP_URL_BASE_FRONT + value;
  }
  else {
    return value;
  }
}

export function appendString(value, text){
  return value+text;
}