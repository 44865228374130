/**
 * @param integer n: length of decimal
 * @param integer x: length of sections
 */
export function formatNumber( num, d, x ){

    num = num * 1;

    if ( num === null || isNaN(num)) { 
        return '' 
    }

    if(typeof(x)==="undefined") x = 3;
    if(typeof(d)==="undefined") d = 2;

    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (d > 0 ? '\\.' : '$') + ')';
    return num.toFixed(Math.max(0, ~~d)).replace(new RegExp(re, 'g'), '$&,');
}

export function formatCurrency( num ){

	return "$ "+formatNumber( num, 2, 3 );
}