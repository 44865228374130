import api_client from "../modules/api-client.js";

export function addItemCarrito(id_producto, id_variacion, cantidad) {
    return api_client.post("carrito/item",
        {
            "id_producto": id_producto
            , "id_variacion": id_variacion
            , "cantidad": cantidad
        });
}

export function getDetallesCarrito() {
    return api_client.get("carrito/detalles");
}

export function getPrecioMotor() {
    return api_client.get("carrito/motor");
}

export function updateCantidadCarrito(id_pedido_detalle, id_producto, colocacion, control, cantidad) {
    return api_client.post(
        "carrito/updateItemCantidad",
        {
            'id_pedido_detalle': id_pedido_detalle, 
            'id_producto': id_producto,
            'colocacion': colocacion,
            'control': control,
            'cantidad': cantidad
        });
}

export function removeItemCarrito(id_pedido_detalle) {
    return api_client.post(
        "carrito/deleteItem",
        {
            'id_pedido_detalle' : id_pedido_detalle
        });
}

export function checkOutCarrito(datos) {
    return api_client.post(
        "carrito/checkout",
        datos);
}

export function postPedido(datos) {
    return api_client.post(
        "carrito/Pedido",
        datos);
}

export function generaPDF(datos) {
    return api_client.post(
        "carrito/generaPDF",
        datos);
}

export function resetPedido() {
    return api_client.post(
        "carrito/resetPedido");
}

export function apiService(url,data) {
    return api_client.get(url,data);
}